import { createMuiTheme } from '@material-ui/core/styles'
import { heading } from '../../utils/utils'

const mainFont = 'Roboto'

const white = '#fff'
const black = '#000'
const greyLight = '#f0f0f0'
const imp = '!important'

const f10 = `10px`
const f11 = `11px`
const f12 = `12px`
const f14 = `14px`
const f16 = `16px`
const f18 = `18px`
const f20 = `20px`
const f25 = `25px`
const f30 = `30px`
const f35 = `35px`
const f45 = `45px`

const hpTitle = {
  fontSize: `${f30} ${imp}`,

  fontWeight: `400 ${imp}`,
  lineHeight: `1.2 ${imp}`,
  '&>b, &>strong': {
    fontWeight: `400 ${imp}`,
  },
}

const hpTitleSmall = {
  fontSize: `${f25} ${imp}`,
  lineHeight: `1.2 ${imp}`,

  '@media (min-width: 1024px)': {
    fontSize: `${f35} ${imp}`,
  },
}

const body = {
  fontSize: f16,
  // '@media (min-width: 1024px)': {
  //   fontSize: f19,
  // },
  lineHeight: 1.6,
}
const body2 = {
  fontSize: f14,
  // '@media (min-width: 1024px)': {
  //   fontSize: f19,
  // },
  lineHeight: 1.67,
}

const customSelect = {
  fontSize: `14px ${imp}`,
  borderRadius: `6px ${imp}`,
  border: `1px solid #F5F3F8 ${imp}`,
  padding: `7px 10px ${imp}`,
  background: `#F5F3F8 ${imp}`,
  '&:focus': {
    outline: `none ${imp}`,
  },
}

const fontFamily = `${mainFont}, sans-serif`

const theme = createMuiTheme({
  themeFont: {
    main: mainFont,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 480,
      md: 768,
      lg: 1024,
      xl: 1440,
    },
  },
  typography: {
    fontFamily,
    h1: {
      lineHeight: 1.15,
      ...heading(f35, f45, 400, 400, fontFamily, fontFamily, false),
    },
    h2: {
      lineHeight: 1.15,
      ...heading(f25, f35, 400, 400, fontFamily, fontFamily, false),
    },
    h3: {
      lineHeight: 1.4,
      ...heading(f20, f30, 400, 400, fontFamily, fontFamily, false),
    },
    h4: {
      lineHeight: 1.55,
      ...heading(f12, f16, 400, 400, fontFamily, fontFamily, false),
    },
    h5: {
      lineHeight: 1.25,
      ...heading(f11, f14, 400, 400, fontFamily, fontFamily, false),
    },
    h6: {
      lineHeight: 1.25,
      ...heading(f10, f12, 400, 400, fontFamily, fontFamily, false),
    },
    body1: {
      ...body,
    },
    body2: {
      ...body2,
    },
    caption: {
      fontSize: f14,
      lineHeight: 0.8,
      fontWeight: 400,
    },
    button: {
      fontSize: f16,
      lineHeight: 1,
      fontWeight: 400,
      textTransform: 'inherit',
    },
  },
  palette: {
    common: {
      black: black, // Refer to Text color in Figma
      white: white,
    },
    background: {
      default: white,
      paper: white,
    },
    primary: {
      main: black,
    },
    secondary: {
      main: black,
    },
    success: {
      main: '#47E169',
    },
    error: {
      main: '#FA3232',
    },
    warning: {
      main: '#FF8333',
    },
    divider: greyLight,
    primaryTransparent: {
      main: 'rgb(41, 51, 231, .4)',
      light: 'rgb(41, 51, 231, .1)',
    },
    secondaryTransparent: {
      main: 'rgba(229, 76, 128, .4)',
      light: 'rgba(229, 76, 128, .1)',
    },
    ternary: {
      main: '#0082C3',
      light: '#F1FAFF',
    },
    accent: {
      main: '#FFEA28',
    },
  },
  sizes: {
    headerHeight: 60,
    headerHeightTransparent: 90,
    logoWidth: 110,
    logoHeight: 30,
    footerBG: 168,
    basketWidth: 340,
    basketWidthLG: 440,
  },
  extraColors: {
    grey: '#D5D2D9',
    greyLight: '#F0F0F0',
    greyMedium: '#C0BCC6',
    greyText: '#6F677B',
  },
  shadows: [
    'none',
    'none',
    '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
    '0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)',
    '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
    '0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)',
    '0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)',
    '0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)',
    '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)',
    '0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)',
    '0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)',
    '0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)',
    '0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)',
    '0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)',
    '0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)',
    '0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)',
    '0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)',
    '0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)',
    '0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)',
    '0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)',
    '0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)',
    '0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)',
    '0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)',
    '0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)',
    '0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)',
  ],
  spacing: 8,
  shape: {
    borderRadius: 0,
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '.header__logo': {
          maxHeight: `35px ${imp}`,
        },
        '.menu__submenu': {
          minWidth: `250px ${imp}`,
        },
        '.hero__home': {
          '& .title': {
            ...hpTitle,
            '@media (max-width: 1024px)': {
              // paddingTop: `30px ${imp}`,
            },
          },
          '& .tagline': {
            fontSize: `${f18} ${imp}`,
          },
        },
        '.select': {
          ...customSelect,
        },
        '.section-hiw': {
          '@media (max-width: 768px)': {
            maxWidth: `80% ${imp}`,
            margin: `auto ${imp}`,
          },
          '&__title': {},
          '& .MuiContainer-root': {
            paddingLeft: `0 ${imp}`,
            paddingRight: `0 ${imp}`,
            maxWidth: `1360px ${imp}`,
          },
          '& .MuiGrid-grid-md-4': {
            '@media (min-width: 768px)': {
              flexBasis: `${100 / 4}% ${imp}`,
              maxWidth: `${100 / 4}% ${imp}`,
            },
          },
          '&__spacing': {
            '& .hiw__icon': {
              '& .hiw__img': {
                width: `47px ${imp}`,
              },
            },
            '& .hiw__content': {
              '& .hiw__head': {
                '& .hiw__title': {
                  fontSize: `30px ${imp}`,
                  fontWeight: `600 ${imp}`,
                  '@media (min-width: 1024px)': {
                    fontSize: `37px ${imp}`,
                  },
                },
              },
            },
          },
        },
        '.section-discover__subtitle': {
          textAlign: `center`,
        },
        '.section-infos__content': {
          '&> h2': {
            ...hpTitleSmall,
          },
        },
        '.commitment': {
          backgroundColor: `transparent ${imp}`,
          '&__text': {
            textAlign: `center ${imp}`,
          },
        },
        '.faq': {
          '&[aria-expanded=true]': {
            '&>.MuiButton-label:after': {
              transform: 'rotate(-180deg)',
            },
          },
          '&__question': {
            borderBottom: `1px solid ${greyLight} ${imp}`,
            '&[aria-expanded=true]': {
              borderBottom: `none ${imp}`,
            },
          },
        },
        '.product': {
          '&__right': {
            '& .product__bottom': {
              '@media (min-width: 768px)': {
                justifyContent: `flex-start ${imp}`,
              },
              '&-item': {
                fontSize: `${f16} ${imp}`,
                marginRight: `${f10} ${imp}`,
                '@media (max-width: 1024px)': {
                  fontSize: `${f14} ${imp}`,
                  marginRight: `5px ${imp}`,
                },
              },
              '& >a': {
                marginLeft: `${f10} ${imp}`,
                '@media (max-width: 1024px)': {
                  marginLeft: `5px ${imp}`,
                },
              },
            },
          },
        },
        '.deliveryDate, .returnDate': {
          backgroundColor: `${greyLight} ${imp}`,
        },
        '.cart': {
          backgroundColor: `${greyLight} ${imp}`,
        },
        '.shipping-infos__start, .shipping-infos__end': {
          backgroundColor: `${greyLight} ${imp}`,
          '& >p': {
            color: `${black} ${imp}`,
          },
        },
        '.footer__logo': {
          minHeight: `62px ${imp}`,
        },
      },
    },
    MuiButton: {
      root: {
        padding: '12px 80px',
        borderRadius: 0,
      },
      sizeSmall: {
        padding: '4px 16px',
        lineHeight: 1.6,
      },
      sizeLarge: {
        padding: '12px 45px',
      },
      contained: {
        boxShadow: 'none',
        '&:hover, &:focus, &:active, &.selected': {
          boxShadow: 'none',
        },
      },
      outlined: {
        padding: '12px 45px',
      },
      outlinedSizeLarge: {
        padding: '12px 45px',
      },
      outlinedSizeSmall: {
        padding: '4px 16px',
        lineHeight: 1.6,
      },
      label: {
        fontSize: f16,
      },
    },
    MuiPaper: {
      root: {
        boxShadow: '0px 0px 15px rgba(46, 46, 46, 0.1)',
      },
      rounded: {
        borderRadius: 10,
      },
    },
    MuiSelect: {
      root: {
        color: black,
      },
      selectMenu: {
        '&:focus': {
          background: white,
        },
      },
    },
    MuiChip: {
      root: {
        borderRadius: 0,
        fontSize: 14,
        fontWeight: 700,
        color: black,
        backgroundColor: 'transparent',
        height: 25,
        padding: 0,
      },
      label: {
        padding: 0,
      },
      sizeSmall: {
        height: 22,
        fontSize: 14,
        fontWeight: 400,
      },
      labelSmall: {
        paddingLeft: 10,
        paddingRight: 10,
      },
      colorPrimary: {
        color: black,
        backgroundColor: 'transparent',
      },
      colorSecondary: {
        color: black,
        backgroundColor: 'transparent',
      },
    },
  },
})

export default theme
